@import (reference) '../HVJS/HVUI/shared.module.less';

// primary color for all components
// We should really use the darker color for a11y
@primary-color: @ocean300;
@info-color: @ocean400; // Ocean variant that is faded back for info element backgrounds
@primary-dark: @ocean000;

@link-color: @ocean300; // link color
@link-decoration: underline;
@link-hover-decoration: underline;

@btn-primary-bg: @ocean300;

@success-color: @green300; // success state color
@warning-color: @yellow000; // warning state color
@error-color: @red200; // error state color
@error-dark: @red000;

@font-size-base: 16px; // major text font size

@line-height-base: 1.2; // matches what we expect to inherit, in most cases
@heading-color: @gray200; // heading text color
@text-color: @gray100; // major text color
@text-color-secondary: @ocean000; // secondary text color
@disabled-color: @gray300; // disable state color
@border-radius-base: 4px; // major border radius
@border-color-base: @gray400;
@border-width-base: 2px;
@font-family: 'Lato', Helvetica, Arial, sans-serif;

//Tables
@table-row-hover-bg: @gray700;
@table-header-color: @gray200;
@table-header-bg: transparent;
@table-padding-vertical: 14px;
@table-padding-horizontal: 14px;

// vertical paddings
@padding-lg: 48px; // containers
@padding-md: 24px; // small containers and buttons
@padding-sm: 16px; // Form controls and items
@padding-xs: 8px; // small items

@input-height-lg: 42px;

//Buttons

@btn-font-weight: 400;
@btn-border-radius-base: 16px;
@btn-border-radius-sm: (@btn-border-radius-base * 0.8);
@btn-border-width: @border-width-base;
@btn-border-style: @border-style-base;
@btn-shadow: none;
@btn-primary-shadow: none;

@btn-padding-horizontal-base: @padding-md - 1px;
@btn-padding-horizontal-lg: (@btn-padding-horizontal-base * 2);
@btn-padding-horizontal-sm: @padding-sm - 1px;

@btn-height-sm: 24px;
@btn-height-base: 32px;
@btn-height-lg: 40px;

// Calendar
@calendar-item-active-bg: @gray700;

.ant-col .ant-select {
    display: block;
}

.ant-typography a {
    text-decoration: @link-decoration;
}

.ant-select-selection .ant-select-selection__placeholder {
    color: @gray100;
    font-style: italic;
}

.ant-table-wrapper {
    overflow-x: auto;
}

.ant-table-thead > tr > th {
    text-transform: uppercase;
}

.ant-table-tbody > tr > td {
    border-bottom-color: transparent;
}

.ant-divider-horizontal.ant-divider-with-text {
    margin-top: 1.75em;
}

#threadlist {
    .ant-table {
        font-size: 14px; // match the rest of the UI
        & th svg:only-child, // center icon-only column
        td svg:only-child {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .ant-pagination a {
        text-decoration: none;
    }

    .ant-checkbox,
    .ant-checkbox-inner {
        height: 1em;
        width: 1em;
    }

    .ant-checkbox-checked {
        .ant-checkbox-inner {
            &::after {
                left: 24%;
                transform: rotate(45deg) scale(1.4) translate(-50%, -50%);
            }
        }
    }
}

.scheduled {
    .ant-btn {
        width: 100%;
    }

    .ant-table,
    .ant-btn {
        font-size: 16px;
    }

    a.ant-btn {
        line-height: calc(@line-height-base + 0.5);

        span {
            margin-left: 8px;
        }
    }

    .ant-table-thead > tr > th > i {
        display: inline;
        position: static;

        > svg {
            margin-left: 4px;
            position: static;
        }
    }

    // hack to fix the table layout when we ask it to scroll on small screens.
    .ant-table-layout-fixed table {
        table-layout: auto;
    }
}

// Alerts

.ant-alert {
    padding: var(--16px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-style: solid;
    border-radius: var(--8px);

    & .anticon {
        margin-right: var(--8px);
    }

    & svg {
        width: var(--16px);
        height: var(--16px);
    }

    &.ant-alert-with-description {
        align-items: flex-start;
        & .anticon {
            margin-top: var(--5px);
            display: block;
        }
    }
}

.ant-alert-content {
    color: var(--gray100);
    flex: inherit;
}

.ant-alert-message,
.ant-alert-with-description .ant-alert-message {
    line-height: var(--24px);
    font-size: var(--16px);
}

.ant-alert-error {
    border-color: var(--red300);
    background-color: var(--red500);
}

.ant-alert-info {
    border-color: var(--ocean500);
    background-color: var(--ocean800);
}

.ant-alert-success {
    border-color: var(--green300);
    background-color: var(--green500);
}

.ant-alert-warning {
    border-color: var(--yellow000);
    background-color: var(--yellow500);
}

// pagination
.ant-pagination-options-size-changer .ant-select-arrow {
    // changing the base font size breaks the alignment
    margin-top: -4px;
    right: 10px;
}

.ant-pagination-simple-pager {
    margin-top: -1px;
    color: @ocean300;
    font-weight: 500;
}

.ant-pagination.ant-pagination-simple {
    & button.ant-pagination-item-link {
        border: 2px solid @gray500;
        & svg {
            position: relative;
            top: -2px;
        }
    }
    & .ant-pagination-simple-pager input {
        padding-bottom: 2px;
    }
}

.ant-popover-inner-content .ant-typography {
    font-size: 14px;
}

// This is for making the icons more clickable when we enable them
.ant-table-column-sorter {
    .ant-table-column-sorter-inner {
        margin-top: -0.5em;
    }

    .ant-table-column-sorter-up,
    .ant-table-column-sorter-down {
        font-size: 16px;
    }
}

.ant-btn {
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    font-weight: 400;
}

.ant-btn-sm > .anticon + span {
    margin-left: 4px;
}

.ant-btn-primary {
    &:hover,
    &:focus {
        background: @primary-color;
        border-color: @primary-dark;
    }
}

.ant-btn-danger {
    &:hover,
    &:focus {
        background: @error-dark;
    }
}

.manage-logo {
    .ant-card-body {
        padding: 0;
    }
}

.ant-message {
    // fix conflicts w/ CW navbar
    z-index: 9999;
}

//-- Forms & Inputs

.ant-col.ant-form-item-label {
    align-self: auto;
    //FIX ME: see if we can use variables for this
    padding: 0 0 8px 12px;
    white-space: pre-wrap;
}

.ant-form legend {
    border-bottom: 0px;
    font-size: 16px;
}

// Checkbox

.ant-checkbox-inner {
    height: 1.4em;
    width: 1.4em;

    &::after {
        top: 60%;
        left: 25%;
    }
}

.ant-checkbox-checked {
    .ant-checkbox-input:focus {
        + .ant-checkbox-inner {
            border: @gray200 2.5px solid;
        }
    }

    .ant-checkbox-inner {
        &::after {
            transform: rotate(39deg) scale(1.8) translate(-50%, -50%);
            width: 4.4px;
            height: 6.6px;
            border-width: 1px;
            left: 7px;
            top: 10px;
        }
    }
}

//Radio

@radio-size: 20px;

.ant-radio {
    line-height: 1;
}

.ant-radio-inner {
    border: @gray100 solid 1px;
    box-sizing: content-box;

    &::after {
        transform: scale(0.8) !important;
        display: block;
        top: 50%;
        left: 50%;
        margin-left: (-(@radio-size) / 2);
        margin-top: (-(@radio-size) / 2);
    }
}

//Table
.ant-table-row-expand-icon::after {
    top: 3px;
}

// Workaround for removable fields in CW / RDW
.flex-field-button {
    .ant-col {
        padding-left: 0;
        padding-right: 0;
    }

    .ant-form-item-control-input-content {
        display: flex;
        justify-content: stretch;
    }

    .ant-form-item {
        margin-bottom: 8px;
    }
}

// Card
// fix title not wrapping
.ant-card-head-title {
    white-space: normal;
}

.ant-picker-cell-selected > div {
    font-size: 1rem;
    font-weight: var(--bold);
}

.ant-picker-cell-disabled {
    background: @gray800;
}

.ant-picker-dropdown {
    z-index: 9999;
}

.ant-table-filter-trigger {
    align-self: center;
}

.ant-modal-confirm-body {
    svg {
        vertical-align: middle;
    }

    svg + .ant-modal-confirm-title {
        display: inline-block;
        margin-left: 0.5em;
        vertical-align: middle;
    }
}

.ant-checkbox-wrapper {
    display: inline-flex;
}
.ant-checkbox-checked::after {
    height: 1.4em;
}

select.ant-input[disabled],
input.ant-input[placeholder='MM/DD/YYYY'][disabled] {
    border: none;
    background: white;
    color: @gray100;
    padding-left: 0;
}

select.ant-input[disabled] {
    cursor: default;
}

.ant-form-item-no-colon span {
    color: @error-color;
}

// TODO refactor these away from id selector
#study-modal {
    .ant-row.ant-form-item {
        margin-bottom: 0;
    }
}

#create-cycle-modal {
    .ant-row.ant-form-item {
        margin-bottom: 0.8rem;
    }
}

// Care Plan cycle actions table
.cycles-table .not-clickable td {
    cursor: default;
}

.cycles-table .clickable td:hover {
    text-decoration: underline;
    color: var(--ocean200);
}

// Actually hide hidden fields in FormEdit
.CheckInForm .ant-row:has(input[type='hidden']),
.CheckInForm input[type='hidden'],
.CheckInForm input[type='hidden'] + div {
    display: none;
}
